@font-face {
    font-family: 'LemonMilk-medium';
    src: local('LemonMilk-medium'), url('../assets/LemonMilkMedium-mLZYV.otf') format('opentype');
}

div {
    overflow: hidden;
}

.home0Container {
    /* background-color: rgb(51, 51, 51); */
    background-color: black;
    background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../assets/images/backgroundGrid0.PNG');
    /*  background-image: url('../assets/images/backgroundPerlinColorLR.PNG'); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    color: white;
    display: table-cell;
    vertical-align: middle;
    margin: auto;
    width: 100vw;
    text-align: center;
    /* animation: fadeInAnimation ease 3s; */
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.home0Container h1 {
    font-family: 'LemonMilk-medium';
    color: rgb(137, 203, 204);
    margin: auto;
    text-align: center;
    margin-left: auto;
    font-size: 100px;
    margin-right: auto;
}

.home0Container h2 {
    color: rgb(137, 203, 204);
    font-family: 'LemonMilk-medium';
    font-size: 35px;
}


.logoContainerOuter{
    margin: auto;
    position: relative;
    width: 400px;
    height: 400px;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.logoRingX{
    position: absolute;
    width: 100%;
    left: 0px;
    animation: spin 50s infinite linear;
    filter: brightness(1.5);
}

.logoCenterX{
    position: absolute;
    width: 65%;
    top: 70px;
    left: 65px;
}

.logoContainer{
    position: relative;
    height: 30vw;
}

.logoRing{
    position: absolute;
    top: 1%;
    left: 25%;
    width: 50%;
    animation: spin 50s infinite linear;
    z-index: 9;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.logoCenter {
    position: absolute;
    top: -5%;
    left: 25%;
    width: 50%;
    z-index: 10;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

/*  */

.home1Container {
    /* background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), url('../assets/images/backgroundPerlinColorSilk.PNG'); */
    background-size: cover;
    color: white;
    /*  linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../assets/images/backgroundPerlinColorSilk.PNG');
    */
    /* height: 100vh; */
    text-align: center;
    vertical-align: middle;
    margin: auto;
}

.home1Container h1{
    font-family: 'LemonMilk-medium';
    color: rgb(137, 203, 204);    
    font-size: 55px;
}

.home1Container p{
    font-family: 'LemonMilk-medium';
    color: rgb(120, 105, 133);
    word-break: keep-all;
    width: 70%;
    font-size: 20px; /* 1.4vw; */
    text-align: justify;
    margin: auto;
    margin-top: 40px;
    max-width: 1500px;
}

/*  */

.home1Container0{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title1Container{
    float: left;
    font-size: 2vw;
    width: 50%;
    text-align: right;
    margin: auto;
}

.introTextContainer {
    display: block;
    float:left;
    width: 50%;
    text-align: left;
    margin: auto;
}

/*  */

.statAndCarouselContainerOuter{
    margin:auto;
    margin-top: 40px;
    width: 1040px; /* 100%; */
    /* max-width: 1400px; */
    /* background-color: rgb(51, 51, 51); */
}

.statContainer{
    color: white;
    float:left;
    width: 400px;/* 40%; */
    margin: 10px 10px;
    text-align: center;
    font-family: 'LemonMilk-medium';
}

.carouselContainerOuter{
    float:left;
    width: 600px; /* 60%; */
    text-align: center;
    margin: 10px 10px;
/*     min-width: 400px; */
}

.carouselContainer {
    /* background-color: rgb(51, 51, 51); */
    margin: auto;
    /* margin-top: 40px; */
    /* width: 50%; */
    width: 600px;
/*     max-width: 800px;
    min-width: 400px; */
}

.carousel ul {
    padding-left: 0px;
}

/* .statBoxInner{
    max-width: 400px; 
} */

.statBoxInner:nth-child(1){
    background-color: rgb(99, 62, 131); /* rgb(137, 180, 180); */ /* rgb(117, 133, 105); */
}

.statBoxInner:nth-child(2){
    background-color: rgb(104, 76, 129); /* rgb(141, 133, 148); */ /* rgb(133, 105, 120); */
}

.statBoxInner:nth-child(3){
    background-color: rgb(116, 95, 133); /* rgb(137, 180, 180); */   /* rgb(133, 121, 105); */
}

.statBoxInner:nth-child(4){
    background-color: rgb(118, 105, 129); /* rgb(141, 133, 148); */ /* rgb(105, 131, 133); */
}

.statBoxInner h1{
    font-size: 74px;
    margin: 2%;
    margin-bottom: 1%;
}

/*  */

.statBoxOuterContainer{
    max-width: 1800px;
    margin:auto;
}

.statBoxContainer h1{
    color: white;
}
.statBoxContainer1 h1{
    color: white;
}

.statBox {
    width:50%;
    float:left;
}

.statBox:nth-child(1){
    background-color: rgb(117, 133, 105);
}
.statBox:nth-child(2){
    background-color: rgb(133, 105, 120);
}

.statBox h1{
    font-size: 100px;
    margin: 2%;
    margin-bottom: 1%;
}

.statBoxContainer1 .statBox:nth-child(1){
    background-color: rgb(133, 121, 105);
}

.statBoxContainer1 .statBox:nth-child(2){
    background-color: rgb(105, 131, 133);
}


/*  */

.phasesContainer{
    background: rgb(4,100,211);
    background: linear-gradient(180deg, rgba(4,100,211,1) 6%, rgba(210,212,214,1) 95%);
    width: 100%;
}

/* .phasesContainerInner{
    margin: auto;
    width: 90%;
} */

.timePeriodContainer{
    margin:auto;
    max-width: 1800px;
}

.timePeriodSubcontainer{
    float:left;
    width: 33%;
    text-align: center;
}


.timePeriodSubcontainer:first-of-type{
    width: 20%;
    margin-left: 12.5%;
}

.timePeriodSubcontainer:nth-of-type(2){
    width: 20%;
}

.timePeriodSubcontainer:last-of-type{
    width: 35%;
    /* margin-right: 12.5%; */ /* equal to first-of-type margin-left */
}

.timePeriodContainer h1{
    font-size: 30px;
    color: white;
}

.timePeriodContainer:nth-of-type(1) {
    background-color: rgb(4, 100, 211);
}

.timePeriodContainer:nth-of-type(2) {
    background-color: rgb(27, 112, 209);
}

.timePeriodContainer:nth-of-type(3) {
    background-color: rgb(56, 128, 209);
}

.timePeriodContainer:nth-of-type(4) {
    background-color: rgb(83, 143, 212);
}

.timePeriodContainer:nth-of-type(5) {
    background-color: rgb(108, 156, 212);
}

.timePeriodContainer:nth-of-type(6) {
    background-color: rgb(129, 167, 212);
}

.timePeriodContainer:nth-of-type(7) {
    background-color: rgb(148, 178, 214);
}

.timePeriodContainer:nth-of-type(8) {
    background-color: rgb(171, 192, 217);
}

.timePeriodContainer:nth-of-type(9) {
    background-color: rgb(189, 201, 217);
}

.timePeriodContainer:nth-of-type(10) {
    background-color: rgb(199, 206, 214);
}

/*  */

.parallax-ring0 img{
    width: 30vw;
    z-index: inherit;
}

/*  */

.ringTextContainer{
    margin: auto;
    text-align: center;
    z-index: 100;
    position: relative;
    margin-top: 350px;
}

.ringTextContainer h1{
    font-family: 'LemonMilk-medium';
    color: rgb(137, 203, 204);    
    font-size: 55px;
}

.ringTextContainer p{
    font-family: 'LemonMilk-medium';
    color: rgb(120, 105, 133);
    word-break: keep-all;
    width: 70%;
    font-size: 20px; /* 1.4vw; */
    text-align: justify;
    margin: auto;
    margin-top: 40px;
    max-width: 1500px;
}

.codexImageContainer{
    margin-top:50px;
    margin-bottom: 50px;
}

.codexImageContainer p {
    margin: auto;
    text-align: center;
}

.codexImage{
    margin: auto;
    width: 100%;
    max-width: 800px;
    /* border-radius: 10px; */
}

.ringContainerOuter{
    position: relative;
    height: 2250px;
    background: rgb(210,212,214);
    /* background: linear-gradient(180deg, rgba(210,212,214,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 90%); */
    background: linear-gradient(180deg, rgba(210,212,214,1) 0%, rgba(100,100,100,1) 40%, rgba(70,70,70,1) 100%);

}

.ringImageContainer{
    width: 2200px; /* fixed */
    display: block;
    margin: auto;
    position: absolute;
    top: 0px;
    left: calc((-1100px + 50%));
    z-index: 99;
    margin-top: 30px;
}

.ringImage{
/*     margin: auto;
    display: block; */
    width: 100%;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

/*  */

.introText{
    width: 50%;
    margin: auto;
}

.dataBox{
    width: 450px;
    text-align: center;
    margin:auto;
    height: 200px;
    display: block;
    line-height: 200px;
    padding-top: 1%;
}

.dataBox p {
    background-color: red;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.dataBoxP1 {
    border-radius: 15px;
    background-color: red;
    width: 200px;
    float:left;
}

.dataBoxP1 h3 {
    font-size: 20vw;
}

.dataBoxP2 {
    border-radius: 15px;
    float:right;
    background-color: red;
    width: 200px;
    line-height: 200px;
}

/*  */

.tokenomicsContainerOuter{
    background-color: rgb(70, 70, 70);
}

.tokenomicsContainer{
    background-size: cover;
    color: rgb(51, 51, 51);
    text-align: center;
    vertical-align: middle;
    margin: auto;  
    max-width: 2000px;
}

.tokenomicsContainer h1{
    font-family: 'LemonMilk-medium';
    color: rgb(137, 203, 204);
    font-size: 55px;
}

.tokenomicsContainer ul{
    list-style-type: hieroCounter;
    width: 70%;
    margin: auto;
}

.tokenomicsContainer li {
    color: rgb(188, 164, 209);
    font-size: 70px;
    padding-top: 10px;
    line-height: 10px;
}

.tokenomicsContainer li p{
    font-family: 'LemonMilk-medium';
    color: rgb(188, 164, 209);
    word-break: keep-all;
    margin-top: 0%;
    /* margin-bottom: 0%; */
    font-size: 20px; /* 1.4vw; */
    text-align: justify;
    line-height: 25px;
}

@counter-style hieroCounter {
    system: fixed;
    symbols: 𓍢 𓍣 𓍤 𓍥 𓍦 𓍧 𓍨 𓍩;
    suffix: " ";
  }


.footerContainer {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgb(51, 51, 51);
    height: 200px;
    color: rgba(255, 255, 255, 0.3);
    text-align: center;
    padding-top: 70px;
    padding-bottom: 40px;
}

.footerContainer h2{
    font-size: 15px;
}

.footerContainer h2:first-of-type{
    font-size: 40px;
    margin: 0%;
    transition: all 0.7s ease;
}

.footerContainer:hover > h2:first-of-type{
    color: rgb(137, 203, 204);
}

/* */


.linkIconImageFooter{
    height: 30px;
    filter: grayscale(100%);
    margin-left: 10px;
    margin-right: 10px;
    transition: all 0.7s ease;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.linkIconImageFooter:hover{
    filter: grayscale(10%);
}

.linkIconImageHeader{
    height: 40px;
    filter: grayscale(80%);
    margin-left: 10px;
    margin-right: 10px;
    transition: all 0.7s ease;
    margin-bottom: 40px;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.linkIconImageHeader:hover{
    filter: grayscale(10%);
}

/*  */

@media (max-width: 1050px) {

    .statAndCarouselContainerOuter{
        margin:auto;
        margin-top: 40px;
        width: 100%;
        /* max-width: 1400px; */
        /* background-color: rgb(51, 51, 51); */
    }    

    .statContainer{
        float:none;
        color: white;
        width: 100%;
        /* max-width: ; */
        margin: auto;
        font-family: 'LemonMilk-medium';
    }

    /*  */
    
    .carouselContainerOuter{
        float: none;
        width: 100%;
        margin: auto;
        margin-top: 40px;
    /*     min-width: 400px; */
    }

    .carouselContainer {
        width: 90%;
    }

    /*  */

    .timePeriodContainer h1{
        font-size: 3vw;
    }

    /*  */

    .ringTextContainer h1{
        font-size: 5vw;
    }

}

@media (max-width: 650px) {

    .logoContainerOuter{
        position: relative;
        width: 300px;
        height: 300px;
    }

    .logoRingX{
        position: absolute;
        width: 100%;
        left: 0px;
        animation: spin 50s infinite linear;
        filter: brightness(1.5);
    }
    
    .logoCenterX{
        position: absolute;
        width: 65%;
        top: 55px;
        left: 50px;
    }

    .home0Container h1 {
        font-size: 17vw;
    }
    
    .home0Container h2 {
        font-size: 4vw;
    }
    
    .linkIconImageHeader{
        height: 5vw;
    }

    /*  */

    .home1Container h1{
        font-size: 8vw;
    }
    
    .home1Container p{
        width: 70%;
        font-size: 3.5vw; /* 1.4vw; */
        margin-top: 7%;
    }

    /*  */

    .statBoxInner h1{
        font-size: 14vw;
        margin: 2%;
        margin-bottom: 1%;
    }

    .statBoxInner h2{
        font-size: 5vw;
    }

    .timePeriodContainer h1{
        font-size: 3vw;
    }

    /*  */

    .ringTextContainer h1{
        font-size: 6vw;
    }
    
    .ringTextContainer p{
        width: 70%;
        font-size: 3.5vw; /* 1.4vw; */
        margin-top: 6%;
    }

    .ringImageContainer{
        width: calc(100% * 3.6); /* not fixed */
        left: calc((-100% * 1.8 + 50%));
    }

    .ringContainerOuter{
        height: calc((100vw * 3.8));
    }

    .ringTextContainer{
        margin-top: calc(100% / 2.5); /* 250px; */
    }

    .codexImageContainer{
        margin-top: 10%;
        margin-bottom: 10%;
    }
    
    .codexImageContainer p {
        margin-top: 1%;
    }

    /*  */

    .tokenomicsContainer h1{
        font-size: 8vw;
    }
    
    .tokenomicsContainer li {
        font-size: 10vw;
        padding-top: 10px;
        line-height: 10px;
    }

    .tokenomicsContainer li p{
        font-size: 3.5vw; /* 1.4vw; */
        line-height:normal;
    }
    
}
